/**
 * Class for determining next column number, reset when reached maximum
 * Also return delay value for staggering revals animations
 */

class indexColumns {
  constructor(columnNumber = 0, columnMax = 6) {
    this.columnNumber = columnNumber
    this.columnMax = columnMax
    this.delay = 75
    this.looped = false
  }

  reset() {
    this.columnNumber = 0
    this.looped = false
  }

  increment() {
    if (this.columnNumber < this.columnMax) {
      this.columnNumber += 1
    } else {
      this.columnNumber = 1
      this.looped = true
    }
    return this.columnNumber
  }

  getDelay() {
    return (this.columnNumber * this.delay) / 1.25
  }
}

export default indexColumns
